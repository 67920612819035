import React from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import {useSiteState} from '~context/siteContext'
import { mobile, tablet } from '~styles/global'
import useBreakpoint from '~utils/useBreakpoint'
import BouncingBall from '~components/BouncingBall'
import { useRef } from 'react'


const Footer = ({ className, hideBall }) => {
	
	const { siteTitle, address, addressLink, instagramHandle, enquiriesText, acknowledgment, ballColours } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()
	const { isMobile } = useBreakpoint()
	const containerRef = useRef()

	return(
		<>
			{siteState.newsletterPopUp && 
			<Global styles={css`
				html, body {
					overflow: hidden;
				}
			`}/>
			}
			<Wrap className={className + (!isMobile && ' sm')} ref={containerRef}>
				<div ref={containerRef}>
					<Section>
						<AddressLinks>
							<Address>
								<RichText content={address}/>
							</Address>
							<NewsletterButton 
								onClick={() => setSiteState(prevState => ({
									...prevState, 
									newsletterPopUp: true
								}))}>
						Newsletter
							</NewsletterButton>
							<div>
								<Instagram link={{ linkType: 'external', url: `https://www.instagram.com/${instagramHandle}`, blank: true }}>Instagram</Instagram>
							</div>
						</AddressLinks>
						<Contact>
							<RichText content={enquiriesText} />
						</Contact>
						<AckCredits>
							<Ack>
								<RichText content={acknowledgment}/>
							</Ack>
							<Credits>
						© {new Date().getFullYear()} {siteTitle}. All rights reserved. 
							</Credits>
						</AckCredits>
					</Section>
					{!siteState.hideBall && 
						<BouncingBall colours={ballColours} boundingBox={containerRef.current} footer={true}/>
					}
				</div>
			</Wrap>
		</>
	)
}

const Wrap = styled.footer`
	padding-top: 48px;
	padding-bottom: 64px;
	position: relative;
	box-sizing: border-box;
	button{
		grid-column: span 12;
		text-align: left;
	}
	${mobile}{
		padding-top: 42px;
		padding-bottom: 48px;
	}
	:before{
		content: '';
		position: absolute;
		top: -1px;
		left: 0;
		right: 0;
		border-bottom: 1px solid var(--black);
	}
`
const AddressLinks = styled.div`
	grid-column: span 2;
	${tablet}{
		grid-column: span 3;
	}
	${mobile}{
		grid-column: span 12;
		margin-bottom: 22px;
	}
`
const Address = styled.div`
	margin-bottom: 26px;
	${mobile}{
		max-width: 245px;
	}
`
const NewsletterButton = styled.button`
	display: block;
	:hover{
		text-decoration: underline;
	}
`
const Instagram = styled(SanityLink)`
	:hover{
		text-decoration: underline;
	}
`
const Contact = styled.div`
	grid-column: 3/7;
	${tablet}{
		grid-column: 4/8;
	}
	${mobile}{
		grid-column: span 12;
		margin-bottom: 22px;
	}
`
const AckCredits = styled.div`
	grid-column: 9/13;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${mobile}{
		grid-column: span 12;
	}
`
const Ack = styled.div`
	${mobile}{
		margin-bottom: 48px;
	}
`
const Credits = styled.div`
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
