import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Headroom from 'react-headroom'
import { useEffect } from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import { LarrSmall, Uarr } from '~components/Svg'

const StickyButtons = ({ className, location }) => {

	const [top, setTop] = useState(undefined)
	const [pinned, setPinned] = useState(undefined)
	const [initial, setInitial] = useState(undefined)

	useEffect(() => {
		setInitial(window.innerHeight)
	}, [])	

	useEffect(() => {
		if(window.pageYOffset > 60){
			setTop(false)
		} else if (window.pageYOffset < 60){
			setTop(true)
		}
		window.addEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
		return () => window.removeEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
	}, [])

	const headerdata = useStaticQuery(graphql`
	query {
		sanityWorkPage {
			slug {
				current
			}
		}
		sanityAboutPage {
			slug {
				current
			}
		}
	}
`)

	return (
		<Wrap className={className} pinned={pinned} initial={initial} top={top} disable={location?.pathname.includes(headerdata?.sanityAboutPage?.slug?.current)}>
			<Inner>
				<Buttons>
					<IndexButton
						to={`/${headerdata?.sanityWorkPage?.slug?.current}`}
						className='xs'
						show={location?.pathname?.includes('projects')}
					>
						<SmallArrow />
					Index
					</IndexButton>
					<ScrollToTop 
						onClick={() => window.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth'
						})}>
						<UpArrow />
					</ScrollToTop>
				</Buttons>
				<StyledHeadroom           
					onPin={() => setPinned(true)}
					onUnpin={() => setPinned(false)}
					onUnfix={() => setPinned(false)}
				>
				</StyledHeadroom>
			</Inner>
		</Wrap>
	)
}

const StyledHeadroom = styled(Headroom)`
	display: none;
	${mobile}{
		display: block;
	}
`
const Wrap = styled.div`
	display: ${ props => props.disable ? 'none' : 'flex'};
	position: sticky;
	pointer-events: none;
	align-items: flex-end;
	justify-content: flex-end;
	opacity: ${ props => props.pinned && !props.top ? '1' : '0'};
	box-sizing: border-box;
	transition: opacity 0.2s;
	z-index: 100;
	height: 0;
	left: 0;
	right: 0;
	bottom: 0;
`
const Inner = styled.div`
	position: absolute;
	padding: 20px;
`
const Buttons = styled.div`
	display: flex;
`
const IndexButton = styled(Link)`
	pointer-events: all;
	background-color: var(--white);
	border: 1px solid var(--black);
	border-radius: 60px;
	height: 30px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 9px;
	margin-right: 10px;
	display: ${ props => props.show ? 'block' : 'none'};
`
const ScrollToTop = styled.button`
	pointer-events: all;
	background-color: var(--white);
	border: 1px solid var(--black);
	border-radius: 60px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`
const UpArrow = styled(Uarr)`	
	width: 12px;
`
const SmallArrow = styled(LarrSmall)`
	width: 11px;
	margin-right: 5px;
	position: relative;
	top: 1px;
`

StickyButtons.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
}

export default StickyButtons