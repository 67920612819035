import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Section from '~components/Section'
import Headroom from 'react-headroom'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import { css, Global } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import Footer from '~components/Footer'

const Header = ({ className, location }) => {

	const [pinned, setPinned] = useState(undefined)
	const [top, setTop] = useState(undefined)
	const [open, setOpen] = useState(false)

	const headerdata = useStaticQuery(graphql`
		query {
			sanityAboutPage {
				slug {
					current
				}
			}
			sanityWorkPage {
				slug {
					current
				}
			}
		}
	`)

	useEffect(() => {
		if(window.pageYOffset > 60){
			setTop(false)
		} else if (window.pageYOffset < 60){
			setTop(true)
		}
		window.addEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
		return () => window.removeEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
	}, [])

	useEffect(() => {
		setOpen(false)
	}, [location])

	return(
		<>	
			{open &&
				<Global styles={css`
					html, body{
						overflow: hidden;
					}
				`}/>
			}
			<StyledHeadroom           
				onPin={() => setPinned(true)}
				onUnpin={() => setPinned(false)}
			>
				<Wrap 
					className={className} 
					pinned={ pinned } 
					pinnedMobile={!location?.pathname?.includes(headerdata?.sanityAboutPage?.slug?.current) && pinned }
					top={top}
					open={open}
				>
					<Section>
						<Col><NavLink top={top} className='h1' activeClassName='active' to={`/${headerdata?.sanityWorkPage?.slug?.current}`}>Work</NavLink></Col>
						<Col><NavLink top={top} className='h1' activeClassName='active' to={`/${headerdata?.sanityAboutPage?.slug?.current}`}>Studio</NavLink></Col>
						<MobileToggle 
							className='h1'
							onClick={() => {setOpen(!open)}}
						>
							{open ? 'Close' : 'Menu'}
						</MobileToggle>
						<LogoContainer><Logo to={'/'} top={top} className='h1'>A Friend of Mine</Logo></LogoContainer>
					</Section>
				</Wrap>
			</StyledHeadroom>
			<AnimatePresence exitBeforeEnter>
				{open &&
				<MobileMenu 
					transition={{ duration: 0.4 }} 					
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					open={open}
					key={open}
				>
					<MenuSection>
						<NavMobile>
							<div><NavLink className='h1' to={`/${headerdata?.sanityWorkPage?.slug?.current}`} open={open}>Work</NavLink></div>
							<div><NavLink className='h1' to={`/${headerdata?.sanityAboutPage?.slug?.current}`} open={open}>Studio</NavLink></div>
							<div><NavLink className='h1' to={'/'} open={open}>Home</NavLink></div>
						</NavMobile>
					</MenuSection>
					<Footer />
				</MobileMenu>
				}
			</AnimatePresence>
			<Overlay open={open} onClick={() => setOpen(false)}/>
		</>
	)
}

const StyledHeadroom = styled(Headroom)`
	position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 100;
`
const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 100;
	transform: ${ props => props.pinned || props.top ? 'translateY(0)' : 'translateY(-100%)'};
	background-color: ${ props => props.top ? 'transparent' : 'var(--white)'};
	transition: transform 0.25s, background-color 0.25s, color 0.25s;
	transition-delay: 0s, 0.25s, 0s;
	height: 65px;
	color: ${ props => ( props.top && !props.open )? 'var(--headerColor)' : 'var(--black)'};
	${mobile}{
		transform: ${ props => props.pinnedMobile || props.top ? 'translateY(0)' : 'translateY(-100%)'};
	}
`
const Col = styled.div`
	grid-column: span 2;
	height: 65px;
	display: block;
	display: flex;
	align-items: center;
	${mobile}{
		display: none;
	}
`
const NavLink = styled(Link)`
	pointer-events: all;
	position: relative;
	:after{
		content: '';
		position: absolute;
		top: calc(100% - 4px);
		left: 0;
		right: 0;
		height: 2px;
		transition: 0.25s;
	}
	:hover{
		:after{
			background-color: ${ props => props.top ? 'var(--headerColor)' : 'var(--black)'};
			${mobile}{
				background-color: transparent;
			}
		}
	}
	&.active{
		:after{
			background-color: ${ props => props.top ? 'var(--headerColor)' : 'var(--black)'};
			${mobile}{
				background-color: transparent;
			}
		}
	}
	${mobile}{
		display: inline-block;
		margin-bottom: 40px;
		pointer-events: ${ props => props.open ? 'all' : 'none'};
	}
`
const MobileToggle = styled.button`
	display: none;
	${mobile}{
		display: block;
		pointer-events: all;
		grid-column: 1/6;
		text-align: left;
	}
`
const LogoContainer = styled.div`
	justify-self: end;
	grid-column: 6/13;
	height: 65px;
	display: flex;
	align-items: center;
`
const Logo = styled(Link)`
	pointer-events: all;
	position: relative;
	:after{
		content: '';
		position: absolute;
		top: calc(100% - 4px);
		left: 0;
		right: 0;
		height: 2px;
		transition: 0.25s;
	}
	:hover{
		:after{
			background-color: ${ props => props.top ? 'var(--headerColor)' : 'var(--black)'};
			${mobile}{
				background-color: transparent;
			}
		}
	}
`
const MobileMenu = styled(motion.div)`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 11;
	border-bottom: 1px solid var(--black);
	background-color: var(--white);
	overflow: scroll;
	display: none;
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	${mobile}{
		display: block;
	}
`
const MenuSection = styled(Section)`
`
const NavMobile = styled.nav`
	grid-column: span 12;
	margin-top: 102px;
	margin-bottom: 6px;
`
const Overlay = styled.button`
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	z-index: 2;
`
Header.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
}

export default Header
