import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useAnimationFrame, motion } from 'framer-motion'
import useBreakpoint from '~utils/useBreakpoint'
import { AFOMLogo } from '~components/Svg'

const BouncingBall = ({ className, boundingBox, colours, text, large, footer }) => {

	const { isMobile } = useBreakpoint()

	const ballRef = useRef()
	const ballWidth = ballRef?.current?.getBoundingClientRect().width
	const ballHeight = ballRef?.current?.getBoundingClientRect().height
	const footerWidth = Math.round(boundingBox?.getBoundingClientRect().width)
	const footerHeight = Math.round(boundingBox?.getBoundingClientRect().height)

	const [xDirection, setXDirection] = useState(1)
	const [yDirection, setYDirection] = useState(1)
	const [xPosition, setXPosition] = useState(1)
	const [yPosition, setYPosition] = useState(1)
	const [colourIndex, setColourIndex] = useState(0)
	const [rotate, setRotate] = useState(1)

	const requestRef = useRef()
	const previousTimeRef = useRef()

	const prevTimeStampRef = useRef()

	// useEffect(() => {
	// 	if (isMobile) {
	// 		setYPosition(ballHeight + 120)
	// 	} else {
	// 		setYPosition(ballHeight + footer ? 60 : 80)
	// 	}
	// }, [ballHeight, isMobile])

	const animate = t => {

		if (previousTimeRef.current === undefined) {
			previousTimeRef.current = t
		}

		let timeSinceLastFrame = t - previousTimeRef.current

		timeSinceLastFrame = Math.min(timeSinceLastFrame, 100)

		if (previousTimeRef.current != undefined) {
			setXPosition(prevState => (prevState + (xDirection * Math.round(timeSinceLastFrame) * 0.1)))
			setYPosition(prevState => (prevState + (yDirection * Math.round(timeSinceLastFrame) * 0.1)))
			if (rotate > 360) {
				setRotate(0)
			} else {
				setRotate(prevState => prevState + 1)
			}
		}
		previousTimeRef.current = t
		requestRef.current = requestAnimationFrame(animate)
	}

	useEffect(() => {
		requestRef.current = requestAnimationFrame(animate)
		return () => cancelAnimationFrame(requestRef.current)
	}, [xDirection, yDirection, rotate])

	useEffect(() => {
		if ((xPosition + ballWidth) >= footerWidth) {
			setXDirection(-1)
		} else if (xPosition < 0) {
			setXDirection(1)
		}
		if ((yPosition + ballHeight) >= footerHeight) {
			setYDirection(-1)
		} else if (yPosition < 0) {
			setYDirection(1)
		}
	}, [yPosition, footerWidth, footerHeight])

	useEffect(() => {
		const calcColour = prevState => {
			if (prevState >= (colours.length - 1)) {
				return 0
			} else {
				return prevState + 1
			}
		}
		setColourIndex(prevState => calcColour(prevState))
	}, [yDirection, xDirection])

	return (
		<>
			<Wrap
				className={className}
				ref={ballRef}
				style={{ transform: `translate(${xPosition}px, ${yPosition}px)`, backgroundColor: colours[colourIndex]?.hex }}
				large={large}
			>
				<Rotate style={{ transform: `rotate(${rotate}deg)` }} large={large}>

					{text ?
						<Text>
							Sorry,<br /> Wrong way.
						</Text>
						:
						<Logo />
					}
				</Rotate>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	border-radius: ${props => props.large ? '200px' : '50px'};
	text-align: center;
	padding: 5px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	position: absolute;
	top: 0px;
	left: 0px;
	overflow: hidden;
	pointer-events: none;
	height: ${props => props.large ? '347' : '100'}px;
	width: ${props => props.large ? '347' : '100'}px;
`
const Rotate = styled.div`
	height: ${props => props.large ? '347' : '100'}px;
	width: ${props => props.large ? '347' : '100'}px;
	text-align: center;
	padding: 5px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Logo = styled(AFOMLogo)`
	width: 76px;
`
const Text = styled.h1`
	
`

BouncingBall.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	boundingBox: PropTypes.object,
	colours: PropTypes.array,
	large: PropTypes.bool,
	footer: PropTypes.bool,
}

export default BouncingBall