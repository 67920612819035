module.exports = {
	siteUrl: 'https://afom.gatsbyjs.io',
	buildsUrl: 'https://afom.gtsb.io',
	previewUrl: 'https://preview-afom.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/f885b198-6295-4943-83bf-8f64a7a474df',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'UA-23684496-1'
}
