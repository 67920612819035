import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				highlightColour
				newsletterTitle
				address: _rawAddress(resolveReferences: {maxDepth: 4})
				addressLink
				instagramHandle
				enquiriesText: _rawEnquiriesText(resolveReferences: {maxDepth: 4})
				acknowledgment: _rawAcknowledgment(resolveReferences: {maxDepth: 4})
				ballColours{
					hex
				}
				fourOFourBallColours{
					hex
				}
				fourOFourBallText
				landingAnimationColours{
					slide1Hex
					slide2Hex
					slide3Hex
					slide4Hex
				}
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
