import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SanityLink from '~components/SanityLink'
import { DiagArrow, DiagArrowLarge } from '~components/Svg'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'Banana Grotesk', sans-serif;
		font-size: 24px;
		line-height: 30px;
		${tablet}{
			font-size: 20px;
			line-height: 25px;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--headerColor: #000000;
		--grey: #aaaaaa;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

  p{
		margin-bottom: 0.66em;
    &:last-child{
      margin-bottom: 0;
    }
		${mobile}{
			margin-bottom: 0.78em;
		}
  }

  h1,
  .h1 {
		font-size: 28px;
		line-height: 35px;
    ${tablet} {
			font-size: 24px;
			line-height: 30px;
    }
  }
	.sm {
		font-size: 20px;
		line-height: 25px;
		${tablet}{
			font-size: 16px;
			line-height: 20px;
		}
	}
	.xs {
		font-size: 14px;
		line-height: 10px;
		${tablet}{
			/* font-size: 16px;
			line-height: 20px; */
		}
	}
	.caption {
		font-size: 16px;
		line-height: 20px;
		${tablet}{
			font-size: 14px;
			line-height: 18px;
		}
	}
	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}
`

export const UnderlineLink = styled(SanityLink)`
	position: relative;
	:after {
		content: '';
		position: absolute;
		top: calc(100% - 4px);
		left: 0;
		right: 0;
		height: 2px;
		transition: background-color 0.25s;
		background-color: ${ props => props.white ? 'var(--white)' : 'var(--black)'};
	}
`
export const UnderlineHoverLink = styled(SanityLink)`
	position: relative;
	:after {
		content: '';
		position: absolute;
		top: calc(100% - 4px);
		left: 0;
		right: 0;
		height: 2px;
		transition: background-color 0.25s;
		background-color: transparent;
	}
	:hover{
		:after {
			background-color: var(--black);
		}
	}
`
export const UnderlineHoverButton = styled.button`
	position: relative;
	:after {
		content: '';
		position: absolute;
		top: calc(100% - 4px);
		left: 0;
		right: 0;
		height: 2px;
		transition: background-color 0.25s;
		background-color: transparent;
	}
	:hover{
		:after {
			background-color: var(--black);
		}
	}
`
export const DiagonalArrowSmall = styled(DiagArrow)`
	width: 12px;
	top: 5px;
	position: relative;
	margin-right: 8px;
	min-width: 12px;
	${tablet}{
		width: 10px;
		min-width: 10px;
		top: 5px;
	}
`
export const DiagonalArrowLarge = styled(DiagArrow)`
	width: 13px;
	margin-right: 8px;
	position: relative;
	top: -2px;
	${tablet}{
		width: 12px;
		top: -1px;
	}
`
export const defaultMob = `
	font-size: 20px;
	line-height: 25px;
`
export const h1Mob = `
	font-size: 24px;
	line-height: 30px;
`

const reset = css`
  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

`

export default GlobalStyles
