import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import { Rarr, Larr } from '~components/Svg'
import useWindow from '~utils/useWindow'

const BorderButton = ({ className, link, children, onClick, sm, reverse }) => {

	const [hover, setHover] = useState(false)
	const buttonRef = useRef()
	const textRef = useRef()
	const [transformDistance, setTransformDistance] = useState(undefined)	
	const w = useWindow()

	useEffect(() => {
		setTransformDistance((buttonRef.current?.offsetWidth - textRef.current?.offsetWidth) / 2)
	}, [ 
		buttonRef.current, 
		textRef.current, 
		w.innerWidth
	])

	return (
		<Wrap 
			className={className} 
			link={link} 
			ref={buttonRef}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onClick}
			hover={hover}
			sm={sm}
		>
			{link && 
				<LinkOverlay link={link}/>
			}
			<Text ref={textRef} sm={sm}>
				{reverse &&
					<ArrowCol>
						<ArrowReverse 
							sm={sm}
							hover={hover} 
							transformDistance={transformDistance}
						/>	
					</ArrowCol>
				}
				{children}
				{!reverse &&
					<ArrowCol>
						<Arrow 
							sm={sm}
							hover={hover} 
							transformDistance={transformDistance}
						/>	
					</ArrowCol>
				}
			</Text>
		</Wrap>
	)
}

const Wrap = styled.button`
	border: 1px solid var(--black);
	padding: ${ props => props.sm ? '5px 22px' : '8px 28px'};
	border-radius: 40px;
	position: relative;
	display: inline-flex;
	justify-content: center;
	transition: transform 0.25s, background-color 0.25s, color 0.25s;
	background-color: ${ props => props.hover ? 'var(--black)' : 'transparent'};
	color: ${ props => props.hover ? 'var(--white)' : 'var(--black)'};
	min-width: ${ props => props.sm ? '165px' : '228px'};
	${tablet}{
		padding: ${ props => props.sm ? '5px 22px' : '7px 22px'};
		min-width: ${ props => props.sm ? '165px' : '195px'};
	}
`
const LinkOverlay = styled(SanityLink)`
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	border-radius: 40px;
`
const Text = styled.div`
	display: flex;
	font-size: ${ props => props.sm ? '20px' : 'inherit'};
	${tablet}{
		font-size: ${ props => props.sm ? '16px' : 'inherit'};
	}
`
const ArrowCol = styled.div`
`
const Arrow = styled(Rarr)`
	width: ${ props => props.sm ? '17px' : '18px'};
	margin-left: ${ props => props.sm ? '7px' : '9px'};
	transform: ${ props => props.hover ? `translateX(${props.transformDistance - (props.sm ? 15 : 18)}px)` : '0'};
	transition: transform 0.25s;
	position: relative;
	${tablet}{
		top: ${ props => props.sm ? '1px' : '0'};
		width: ${ props => props.sm ? '15px' : '16px'};
	}
	${mobile}{
		padding-top: 2px;
	}
`
const ArrowReverse = styled(Larr)`
	width: ${ props => props.sm ? '17px' : '18px'};
	margin-right: ${ props => props.sm ? '7px' : '9px'};
	transform: ${ props => props.hover ? `translateX(${ - props.transformDistance + (props.sm ? 15 : 18)}px)` : '0'};
	transition: transform 0.25s;
	position: relative;
	${tablet}{
		top: ${ props => props.sm ? '1px' : '0'};
		width: ${ props => props.sm ? '15px' : '16px'};
	}
	${mobile}{
		padding-top: 2px;
	}
`
BorderButton.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
	onClick: PropTypes.string,
	sm: PropTypes.string,
	reverse: PropTypes.bool,
}

export default BorderButton