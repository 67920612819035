import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import HoverImage from '~components/HoverImage'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)} css={css`:hover{text-decoration: underline;}`}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link to={resolveLink(props.mark.document)} css={css`:hover{text-decoration: underline;}`}>{props.children}</Link> 
			} else {
				return (
					<a 
						css={css`:hover{text-decoration: underline;}`}
						href={props.mark?.url} 
						target={props.mark.blank ? '_blank' : '_self'} 
						rel='noreferrer'
					>{props.children}</a>
				)
			}
		},
		imageWithAlt: props => {
			return (
				<HoverImage image={props?.mark}>
					{props.children}
				</HoverImage>
			)
		},
		dropQuote: props => {
			return (
				<Quote>{props.children}</Quote>
			)
		}
	}
}

const Quote = styled.div`
	position: relative;
	:before{
		/* content: open-quote; */
		content: '“';
		position: absolute;
		top: 0;
		left: -0.7rem; 
		${mobile}{
			left: -0.6rem;
		}
	}
`


const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>

RichText.propTypes = {
	content: PropTypes.array,
}

export default RichText
