import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import useWindow from '~utils/useWindow'

const HoverImage = ({ className, children, image }) => {

	const [hovering, setHovering] = useState(false)
	const elRef = useRef()
	const w = useWindow()
	const [align, setAlign] = useState('left')

	useEffect(() => {
		if(elRef.current && w) {
			const left = elRef?.current?.getBoundingClientRect()?.left
			const threshold = w.innerWidth - 250
			if (left > threshold) {
				setAlign('right')
			} else {
				setAlign('left')
			}
		}
	}, [elRef.current, w])

	return (
		<Wrap className={className} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
			<Span ref={elRef} >{children}</Span>
			<FixedImage image={image} left={align === 'left'} transparent hovering={hovering}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: inline-block;
	position: relative;
`
const Span = styled.div`
	:hover{
		text-decoration: underline;
	}
`
const FixedImage = styled(Image)`
	position: absolute;
	width: 230px;
	bottom: calc(100% + 5px);
	left: ${ props => props.left ? '0' : 'auto'};
	right: ${ props => props.left ? 'auto' : '0'};
	opacity: ${ props => props.hovering ? '1' : '0'};
	pointer-events: none;
`

HoverImage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	image: PropTypes.object,
}

export default HoverImage