
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, defaultMob } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import { useSiteState } from '~context/siteContext'
import MailchimpForm from '~components/MailchimpForm'

const NewsletterPopUp = ({ className, show }) => {

	const { newsletterTitle } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()

	return (
		<Wrap className={className}>
			<PopUp className='sm' show={show}>
				<Close 
					className='caption'
					onClick={() => setSiteState(prevState => ({
						...prevState,
						newsletterPopUp: false
					}))}>
						Close
				</Close>
				<Content>
					<Title>{newsletterTitle}</Title>
					<Form showName successMessage={'Got it, Thanks'}/>
				</Content>
			</PopUp>	
		</Wrap> 
	)
}

const Wrap = styled.div`
	position: fixed;
	bottom: 0;
	right: 0; 
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	z-index: 102;
	${mobile}{
		bottom: auto;
	}
`
const PopUp = styled.div`
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	max-width: 440px;
	background-color: var(--white);
	text-align: center;
	position: relative;
	padding: 60px 25px 36px;
	width: 100%;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
	transform: ${ props => props.show ? 'translateY(0)' : 'translateY(10%)'};
	opacity: ${ props => props.show ? '1' : '0'};
	transition: opacity 0.3s, transform 0.3s;
	${tablet}{
		${defaultMob}
	}
	${mobile}{
		width: 100vw;
		height: 100vh;
		max-width: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 25px;
	}
`
const Close = styled.button`
	position: absolute;
	top: 16px;
	right: 18px;
	:hover{
		text-decoration: underline;
	}
	${mobile}{
		top: 25px;
	}
`
const Content = styled.div`
	
`
const Title = styled.div`
	max-width: 300px;
	margin: 0 auto;
	margin-bottom: 34px;
`
const Form = styled(MailchimpForm)`
	width: 100%;
`

NewsletterPopUp.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
}

export default NewsletterPopUp