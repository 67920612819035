import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import Img from 'gatsby-plugin-sanity-image'
import styled from '@emotion/styled'

const sanityConfig = {
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET
}

const Image = ({ image, maxWidth = 1500, aspectRatio, className, cover, objectPosition, hiRes, transparent }) => {
	let id = image?.asset?._id
	let background = image?.asset?.metadata?.palette?.dominant?.background
	
	const [loaded, setLoaded] = useState(false)
	const imageRef = useRef()

	useEffect(() => {
		if (imageRef?.current?.complete) setLoaded(true)
	}, [imageRef])

	if(!id) return null

	return (
		<Wrap className={className}>
			{aspectRatio ? 
				<div style={{height: 0, paddingBottom: 1 / aspectRatio * 100 + '%', position: 'relative', width: '100%'}}>
					<Img
						{...image}
						alt={image.alt ?? ''}
						onLoad={() => setLoaded(true)}
						ref={imageRef}
						width={maxWidth}
						config={{
							quality: hiRes ? 95 : 75,
							width: hiRes ? 2000 : maxWidth,
						}}
						style={{
							opacity: loaded ? 1 : 0,
							transition: 'opacity 0.35s',
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							display: 'block',
							objectFit: 'cover',
						}}
					/>
				</div> :
				<Img
					{...image}
					alt={image.alt ?? ''}
					onLoad={() => setLoaded(true)}
					ref={imageRef}
					width={maxWidth}
					config={{
						quality: hiRes ? 95 : 75,
						width: hiRes ? 2000 : maxWidth,
					}}
					style={{
						opacity: loaded ? 1 : 0,
						transition: 'opacity 0.35s',
						objectFit: cover ? 'cover' : 'contain',
						objectPosition: objectPosition === 'bottom' ? 'bottom' : objectPosition === 'top' ? 'top' : 'center',
						width: '100%',
						height: cover ? '100%' : 'auto',
						display: 'block'
					}}
				/>
			}
			<Background style={{background}} transparent={transparent}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Background = styled.div`
	position: absolute;
	top: 1px;
	bottom: -1px;
	left: 1px;
	right: -1px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	z-index: -1;
	opacity: ${props => props.transparent ? 0 : 1};
`

Image.propTypes = {
	image: PropTypes.shape({
		asset: PropTypes.shape({
			_id: PropTypes.string.isRequired,
		}),
		alt: PropTypes.string,
		hotspot: PropTypes.object,
		crop: PropTypes.object,
	}),
	aspectRatio: PropTypes.number,
	maxWidth: PropTypes.number,
	className: PropTypes.string,
	cover: PropTypes.bool,
	objectPosition: PropTypes.string,
	hiRes: PropTypes.bool,
	transparent: PropTypes.bool
}

export default Image
