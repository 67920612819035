import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useEffect } from 'react'
import { useState } from 'react'
import useSiteSettings from '~utils/useSiteSettings'

const LandingAnimation = ({ className, location }) => {

	const [initial, setInitial] = useState()
	const [slideIndex, setSlideIndex] = useState(undefined)
	const [close, setClose] = useState()
	const { landingAnimationColours } = useSiteSettings()

	useEffect(() => {
		setInitial(window.innerHeight)
	}, [])

	const slides = [
		{
			text: 'A',
			color: landingAnimationColours?.slide1Hex
		},
		{
			text: 'Friend',
			color: landingAnimationColours?.slide2Hex
		},
		{
			text: 'of',
			color: landingAnimationColours?.slide3Hex
		},
		{
			text: 'Mine',
			color: landingAnimationColours?.slide4Hex
		}
	]

	const pause = async duration => new Promise(resolve => {
		setTimeout(() => {
			resolve()
		}, duration)
	})

	useEffect(async () => {
		if (initial){
			setSlideIndex(0)
			await pause(500)
			setSlideIndex(1)
			await pause(500)
			setSlideIndex(2)
			await pause(500)
			setSlideIndex(3)
			await pause(500)
			setClose(true)
		}
	}, [initial])

	return (
		<>
			{
				!close && 
				<Global styles={css`
					html, body{
						overflow: hidden;
					}
				`}/>
			}
			<Wrap className={className} height={initial} close={close || location.pathname != '/'} firstColor={landingAnimationColours?.slide1Hex}>
				<div>
					{slides.map((slide, index) => (
						<Slide key={slide.text} color={slide?.color} show={slideIndex === index} close={close}>
							<span>{slide.text}</span>
						</Slide>
					))}
				</div>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	position: fixed;
	opacity: ${props => props.close ? 0 : 1};
	transition: opacity 1s 0.5s ease-out;
	pointer-events: ${props => props.close ? 'none' : 'all'};
	left: 0;
	top: 0;
	right: 0;
	z-index: 1000;
	>div {
		position: relative;
		height: 100vh;
		max-height: ${props => props.height}px;
		background-color: ${props => props.firstColor};
	}
`
const Slide = styled.div`
	background-color: ${props => props.color};
	height: ${props => props.height}px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${props => props.show ? 1 : 0};
	font-size: 128px;
	span {
		opacity: ${props => props.close ? 0 : 1};
	}
	${mobile}{
		font-size: 64px;
	}
`

LandingAnimation.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object
}

export default LandingAnimation