import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '~context/siteContext'
import styled from '@emotion/styled'
import { useLocation, createHistory } from '@reach/router'
import '~styles/static.css'
import NewsletterPopUp from '~components/NewsletterPopUp'
import StickyButtons from '~components/StickyButtons'
import { graphql, useStaticQuery } from 'gatsby'
import useSiteSettings from '~utils/useSiteSettings'
import LandingAnimation from '~components/LandingAnimation'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
	const { highlightColour } = useSiteSettings()
	// let history = createHistory(window)
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// useEffect(() => {
	// 	console.log('location', location)
	// 	console.log('history', history.location)
	// }, [history, location])

	const workPageData = useStaticQuery(graphql`
		query {
			sanityWorkPage {
				slug {
					current
				}
			}
		}
	`)

	const hideOverflow = () => {
		if (location.includes('/projects/' && (location !== `/${workPageData?.sanityWorkPage?.slug?.current}` || location !== `/${workPageData?.sanityWorkPage?.slug?.current}/`) )){
			return true
		}
		else {
			return false
		}
	}

	useEffect(() => {
		if (siteState.hideBall){
			setTimeout(() => {
				setSiteState(prevState => ({
					...prevState,
					hideBall: false
				}))
			}, 600)
		}
	}, [location])

	return (
		<>
			<Global styles={css`
				body{
					*::selection {
						background: ${highlightColour};
						color: black;
					}
				}
			`}/>	
			<GlobalStyles />
			<LandingAnimation location={location}/>
			<Header location={location}/>
			<main>
				<Transition>
					<div css={css`
            min-height: calc(100vh);
            display: flex;
            flex-direction: column;
						position: relative;
						overflow: ${ hideOverflow ? 'hidden' : 'unset'};
            ${mobile}{
              min-height: calc(100vh);
            }
          `}>
						{children}
						<Footer css={css`margin-top: auto;`}/>
					</div>
				</Transition>
			</main>
			<StickyButtons location={location}/>
			<NewsletterPopUp show={siteState.newsletterPopUp}/>
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					newsletterPopUp: false,
				}))}
				show={siteState.newsletterPopUp}
			/>
		</>
	)
}

const Overlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	z-index: 101;
	background: rgba(0, 0, 0, 0.5);
	opacity: ${ props => props.show ? '1' : '0'};
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	transition: opacity 0.3s;
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
