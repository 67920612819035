import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import BorderButton from '~components/BorderButton'
import { mobile} from '~styles/global'

const NewsletterSignupForm = ({ className, successMessage, showName }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				// console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success css={css`
        opacity: ${success ? '1' : '0'};
        pointer-events: ${success ? 'inherit' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'inherit'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					<Email
						type="email"
						name="email"
						placeholder="Email"
						error={errorFields.includes('email')}
						onChange={e => setValues({...values, email: e.target.value})}
					/>
					{showName &&
          <>
          	<Input
          	  type="text"
          		name="fname"
          		placeholder="First Name"
          		onChange={e => setValues({...values, fname: e.target.value})}
          	/>
          	<Input
          		type="text"
          		name="lname"
          		placeholder="Last Name"
          		onChange={e => setValues({...values, lname: e.target.value})}
          	/>
          </>
					}
					{errors?.length > 0 &&
            <Errors>
            	{errors.map(error => <p>{error.message}</p>)}
            </Errors>
					}
					<Col>
						<Submit type="submit" sm>
            Subscribe
						</Submit>
						<SubmitMobile type="submit">
            Subscribe
						</SubmitMobile>
					</Col>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
`
const Form = styled.form`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
`
const Col = styled.div`
	grid-column: span 2;
`
const Input = styled.input`
	border: none;
	border-bottom: 1px solid var(--black);
	border-color: var(--black);
	font-family: inherit;
	font-size: inherit;
	height: 40px;
	margin-bottom: 40px;
	width: 100%;
	box-sizing: border-box;
	:hover{
		border-bottom: 1px solid var(--black);
	}
  &:-webkit-autofill{
		border-bottom: 1px solid var(--black);
		:hover{
			border-bottom: 1px solid var(--black);
		}
	}
	::placeholder{
		font-family: inherit;
		color: inherit;
	}
`
const Email = styled(Input)`
	grid-column: span 2;
`
const Errors = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
`

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
	right: 0;
  transition: opacity 0.3s 0.3s;
`
const Submit = styled(BorderButton)`
	margin-top: 10px;
	${mobile}{
		display: none;
	}
`
const SubmitMobile = styled(BorderButton)`
	display: none;
	${mobile}{
		display: flex;
		margin: 10px auto 0;
	}
`
NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
}

export default NewsletterSignupForm
